import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getTotalBookings = createAsyncThunk('GET TOTAL BOOKINGS', async (data, thunkAPI) => {
  let query = `?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&sorting=${data.sorting}&userType=${data.userType}&sortingField=${data.sortingField}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}`;
  if (data?.agencyId) {
    query = `?agencyId=${data?.agencyId}&page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&sorting=${data.sorting}&userType=${data.userType}&sortingField=${data.sortingField}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}`;
  }

  const response = await axios.get(
    `${data?.agencyId ? URL.AGENCY_TOTAL_BOOKINGS : URL.TOTAL_BOOKINGS}${query}`,
    thunkAPI
  );
  return response.data;
});

export const updateTotalBookingRemark = createAsyncThunk('UPDATE_TOTAL_BOOKINGS_REMARKS', async (data, thunkAPI) => {
  const response = await axios.post(URL.UPDATE_TOTAL_BOOKINGS_REMARKS, data, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  totalData: null,
};

export const totalBookingSlice = createSlice({
  name: 'total',
  initialState,
  reducers: {},
  extraReducers: {
    // get total bookings
    [getTotalBookings.pending]: (state) => {
      state.isLoading = true;
      state.totalData = null;
    },
    [getTotalBookings.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.totalData = action.payload.data;
    },
    [getTotalBookings.rejected]: (state, action) => {
      state.isLoading = false;
      state.totalData = null;
      toast.error(action?.payload?.message);
    },

    // update total bookings remarks
    [updateTotalBookingRemark.pending]: (state) => {
      state.isLoading = true;
    },
    [updateTotalBookingRemark.fulfilled]: (state, action) => {
      state.isLoading = false;
      toast.success('Remarks updated successfully');
    },
    [updateTotalBookingRemark.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action?.payload?.message);
    },
  },
});

export default totalBookingSlice.reducer;
