import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getCruise = createAsyncThunk('GET CRUISE', async (data, thunkAPI) => {
  let query = `?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&provider=${data.provider}&startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}&sorting=${data.sorting}&userType=${data.userType}&paymentGateway=${data.paymentGateway}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}`;
  if (data?.agencyId) {
    query = `?agencyId=${data?.agencyId}&page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&provider=${data.provider}&startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}&sorting=${data.sorting}&userType=${data.userType}&paymentGateway=${data.paymentGateway}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}`;
  }
  const response = await axios.get(`${data?.agencyId ? URL.AGENCY_CRUISE : URL.CRUISE}${query}`, thunkAPI);
  return response.data;
});

export const getCruiseDetails = createAsyncThunk('GET CRUISE DETAILS', async (data, thunkAPI) => {
  const response = await axios.get(`${URL.CRUISE}/${data.referenceNumber}`, thunkAPI);
  return response.data;
});

export const resendEmailConfirmation = createAsyncThunk('RESEND_CRUISE_EMAIL_CONFIRMATION', async (data, thunkAPI) => {
  const response = await axios.post(URL.RESEND_CRUISE_CONFIRMATION_EMAIL, data, thunkAPI);
  return response.data;
});

export const updateCruiseRemark = createAsyncThunk('UPDATE_CRUISE_REMARKS', async (data, thunkAPI) => {
  const response = await axios.post(URL.UPDATE_CRUISE_REMARKS, data, thunkAPI);
  return response.data;
});

export const getCruiseLines = createAsyncThunk('GET_CRUISE_LINES', async (data, thunkAPI) => {
  let query = `?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}`;
  const response = await axios.get(`${URL.CRUISE_LINE_SETTINGS}${query}`, thunkAPI);
  return response.data;
});
export const updateCruiseLine = createAsyncThunk('UPDATE_CRUISE_LINE', async (data, thunkAPI) => {
  const response = await axios.post(URL.CRUISE_LINE_SETTINGS, data, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  cruiseData: null,
  cruiseDetails: null,
  cruiseLines: null,
};

export const carSlice = createSlice({
  name: 'cruise',
  initialState,
  reducers: {},
  extraReducers: {
    // get cruise bookings
    [getCruise.pending]: (state) => {
      state.isLoading = true;
      state.cruiseData = null;
    },
    [getCruise.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.cruiseData = action.payload.data;
    },
    [getCruise.rejected]: (state, action) => {
      state.isLoading = false;
      state.cruiseData = null;
      toast.error(action?.payload?.message);
    },

    // get cruise details
    [getCruiseDetails.pending]: (state) => {
      state.isLoading = true;
      state.cruiseDetails = null;
    },
    [getCruiseDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.cruiseDetails = action.payload.data;
    },
    [getCruiseDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.cruiseDetails = null;
      toast.error(action?.payload?.message);
    },

    // update cruise remarks
    [updateCruiseRemark.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCruiseRemark.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Cruise remarks updated successfully');
    },
    [updateCruiseRemark.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload?.message || 'Error updating car remarks');
    },

    // resend cruise confirmation email
    [resendEmailConfirmation.pending]: (state) => {
      state.isLoading = true;
    },
    [resendEmailConfirmation.fulfilled]: (state) => {
      state.isLoading = false;
      // Handle success, e.g., show a success toast message
      toast.success('Email confirmation resent successfully');
    },
    [resendEmailConfirmation.rejected]: (state, action) => {
      state.isLoading = false;
      // Handle error, e.g., show an error toast message
      toast.error(action.payload?.message || 'Error resending email confirmation');
    },

    // get cruise lines
    [getCruiseLines.pending]: (state) => {
      state.isLoading = true;
      state.cruiseLines = null;
    },
    [getCruiseLines.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.cruiseLines = action.payload.data;
    },
    [getCruiseLines.rejected]: (state, action) => {
      state.isLoading = false;
      state.cruiseLines = null;
      toast.error(action?.payload?.message);
    },

    // update cruise line

    [updateCruiseLine.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCruiseLine.fulfilled]: (state, payload) => {
      state.isLoading = false;

      console.log(payload);

      toast.success('Cruise line updated successfully');
    },
    [updateCruiseLine.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload?.message || 'Error updating cruise line');
    },
  },
});

export default carSlice.reducer;
