import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { FileOutlined, PieChartOutlined, UserOutlined, DesktopOutlined, TeamOutlined } from '@ant-design/icons';
import { Layout, Menu, Image } from 'antd';
import HeaderContent from 'components/Header';
import Logo from 'assets/images/logo.png';
import ROUTES from 'config/routes.json';
import { useNavigate } from 'react-router';

const { Header, Content, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('Dashboard', ROUTES.DASHBOARD, <PieChartOutlined />),
  getItem('Manage Admins', ROUTES.MANAGE_ADMINS, <UserOutlined />),
  getItem('Manage Agencies', ROUTES.MANAGE_AGENCIES, <UserOutlined />),
  getItem('Manage Markups', '/manage-markups', <TeamOutlined />, [
    getItem('Platform Markups', ROUTES.MANAGE_PLATFORM_MARKUPS),
    getItem('Service Markups', ROUTES.MANAGE_SERVICE_MARKUPS),
    getItem('Agency Markups', ROUTES.MANAGE_AGENCY_MARKUPS),
    getItem('Default Agency', ROUTES.MANAGE_DEFAULT_AGENCY_MARKUPS),
  ]),
  getItem('Reports', '/reports', <TeamOutlined />, [
    getItem('Hotel Reports', ROUTES.HOTEL_REPORTS),
    getItem('Car Reports', ROUTES.CAR_REPORTS),
    getItem('Flight Reports', ROUTES.FLIGHT_REPORTS),
    getItem('Activity Reports', ROUTES.ACTIVITY_REPORTS),
    getItem('Cruise Reports', ROUTES.CRUISE_REPORTS),
    getItem('Total Reports', ROUTES.TOTAL_REPORTS),
  ]),
  getItem('Settings', '/settings', <TeamOutlined />, [
    getItem('Hotel Radius', ROUTES.HOTEL_RADIUS),
    getItem('Hotel Vendor', ROUTES.HOTEL_VENDOR),
    getItem('Payment Gateway', ROUTES.PAYMENT_GATEWAY),
    getItem('Hotel AutoSuggest', ROUTES.HOTEL_AUTOSUGGEST),
    getItem('Supported Countries', ROUTES.COUNTRY),
    getItem('Cruise Lines', ROUTES.CRUISE_LINE),
    getItem('Locations', ROUTES.LOCATIONS),
    getItem('OTP Signup Enable', ROUTES.OTP),
    getItem('OTP-No Account Created', ROUTES.HIDE_HEADER),
  ]),
  getItem('Searches', ROUTES.SEARCHES, <FileOutlined />),
];

const LayoutWrapper = (props) => {
  const navigation = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const userDetails = useSelector((state) => state.user.userDetails);

  const handleChange = ({ key }) => {
    navigation(key);
  };

  return (
    <Layout className="h-screen">
      <ToastContainer />
      {userDetails && (
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <Image src={Logo} alt="logo" preview={false} width={100} className="m-3" />
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} onClick={handleChange} />
        </Sider>
      )}
      <Layout>
        <Header>
          <HeaderContent />
        </Header>
        <Content className="p-4 h-screen overflow-y-scroll">{props.children}</Content>
      </Layout>
    </Layout>
  );
};
export default LayoutWrapper;
