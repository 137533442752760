import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getFlights = createAsyncThunk('GET FLIGHTS', async (data, thunkAPI) => {
  let query = `?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&provider=${data.provider}&startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&sorting=${data.sorting}&userType=${data.userType}&sortingField=${data.sortingField}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}`;
  if (data?.agencyId) {
    query = `?agencyId=${data?.agencyId}&page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text}&bookingStatus=${data.bookingStatus}&provider=${data.provider}&startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&sorting=${data.sorting}&userType=${data.userType}&sortingField=${data.sortingField}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data.agencyName}`;
  }
  const response = await axios.get(`${data?.agencyId ? URL.AGENCY_FLIGHT : URL.FLIGHT}${query}`, thunkAPI);
  return response.data;
});

export const getFlightDetails = createAsyncThunk('GET FLIGHTS DETAILS', async (data, thunkAPI) => {
  const response = await axios.get(`${URL.FLIGHT}/${data.referenceNumber}`, thunkAPI);
  return response.data;
});

export const resendEmailConfirmation = createAsyncThunk('RESEND_FLIGHT_EMAIL_CONFIRMATION', async (data, thunkAPI) => {
  const response = await axios.post(URL.RESEND_FLIGHT_CONFIRMATION_EMAIL, data, thunkAPI);
  return response.data;
});

export const updateFlightRemark = createAsyncThunk('UPDATE_FLIGHT_REMARKS', async (data, thunkAPI) => {
  const response = await axios.post(URL.UPDATE_FLIGHT_REMARKS, data, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  flightData: null,
  flightDetails: null,
};

export const flightSlice = createSlice({
  name: 'flight',
  initialState,
  reducers: {},
  extraReducers: {
    // get flights
    [getFlights.pending]: (state) => {
      state.isLoading = true;
      state.flightData = null;
    },
    [getFlights.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.flightData = action.payload.data;
    },
    [getFlights.rejected]: (state, action) => {
      state.isLoading = false;
      state.flightData = null;
      toast.error(action?.payload?.message);
    },

    // update flight remarks
    [updateFlightRemark.pending]: (state) => {
      state.isLoading = true;
    },
    [updateFlightRemark.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Remarks updated successfully');
    },
    [updateFlightRemark.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload?.message || 'Error updating remarks');
    },
    // get flight Details
    [getFlightDetails.pending]: (state) => {
      state.isLoading = true;
      state.flightDetails = null;
    },
    [getFlightDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.flightDetails = action.payload.data;
    },
    [getFlightDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.flightDetails = null;
      toast.error(action?.payload?.message);
    },

    // resend hotel confirmation email
    [resendEmailConfirmation.pending]: (state) => {
      state.isLoading = true;
    },
    [resendEmailConfirmation.fulfilled]: (state) => {
      state.isLoading = false;
      // Handle success, e.g., show a success toast message
      toast.success('Email confirmation resent successfully');
    },
    [resendEmailConfirmation.rejected]: (state, action) => {
      state.isLoading = false;
      // Handle error, e.g., show an error toast message
      toast.error(action.payload?.message || 'Error resending email confirmation');
    },
  },
});

export default flightSlice.reducer;
