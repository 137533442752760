import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import load from 'utils/load';
import ROUTES from 'config/routes.json';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import { getUserProfile } from 'reducer/user';

const Login = load(() => import('pages/login'));
const Dashboard = load(() => import('pages/dashboard'));
const ManageAdmins = load(() => import('pages/manageAdmin'));
const ManageAgencies = load(() => import('pages/manageAgencies'));
const PlatformMarkup = load(() => import('pages/platformMarkup'));
const ServiceMarkup = load(() => import('pages/serviceMarkup'));
const AgencyMarkup = load(() => import('pages/agencyMarkup'));
const DefaultAgencyMarkup = load(() => import('pages/defaultAgencyMarkup'));
const HotelReports = load(() => import('pages/hotelReports'));
const CarReports = load(() => import('pages/carReports'));
const FlightReports = load(() => import('pages/flightReports'));
const ActivityReports = load(() => import('pages/activityReports'));
const CruiseReports = load(() => import('pages/cruiseReports'));
const TotalReports = load(() => import('pages/totalReports'));
const HotelRadiusSettings = load(() => import('pages/hotelRadisSettings'));
const HotelVendorSettings = load(() => import('pages/hotelVendorSettings'));
const PaymentGatewaySettings = load(() => import('pages/paymentGatewaysSettings'));
const Searches = load(() => import('pages/searches'));
const AgencyDetails = load(() => import('pages/agencyDetails'));
const AgencySettings = load(() => import('pages/agencySettings'));
const AdminDetails = load(() => import('pages/adminDetails'));
const AssociatedClientDetails = load(() => import('pages/associatedClientDetails'));
const HotelAutoSuggestSettings = load(() => import('pages/hotelAutoSuggestSettings'));
const CountriesSetting = load(() => import('pages/countriesSetting'));
const LocationsSetting = load(() => import('pages/locationsSetting'));
const CruiseLineSetting = load(() => import('pages/cruiseLineSetting'));
const OTPSetting = load(() => import('pages/OTPSetting'));
const HideHeaderSetting = load(() => import('pages/hideHeaderSetting'));

const App = () => {
  const authToken = useSelector((state) => state.user.authToken);

  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      dispatch(getUserProfile());
    }
  }, [authToken]);

  const ProtectedRoute = ({ children }) => {
    if (!authToken) {
      return <Navigate to={ROUTES.ROOT} />;
    }
    return children;
  };

  return (
    <>
      <Loader />
      <Layout>
        <Routes>
          <Route exact path={ROUTES.ROOT} element={<Login />} />
          <Route
            path={ROUTES.DASHBOARD}
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.MANAGE_ADMINS}
            element={
              <ProtectedRoute>
                <ManageAdmins />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.MANAGE_AGENCIES}
            element={
              <ProtectedRoute>
                <ManageAgencies />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.MANAGE_PLATFORM_MARKUPS}
            element={
              <ProtectedRoute>
                <PlatformMarkup />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.MANAGE_SERVICE_MARKUPS}
            element={
              <ProtectedRoute>
                <ServiceMarkup />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.MANAGE_AGENCY_MARKUPS}
            element={
              <ProtectedRoute>
                <AgencyMarkup />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.MANAGE_DEFAULT_AGENCY_MARKUPS}
            element={
              <ProtectedRoute>
                <DefaultAgencyMarkup />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.HOTEL_REPORTS}
            element={
              <ProtectedRoute>
                <HotelReports />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.CAR_REPORTS}
            element={
              <ProtectedRoute>
                <CarReports />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.FLIGHT_REPORTS}
            element={
              <ProtectedRoute>
                <FlightReports />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.ACTIVITY_REPORTS}
            element={
              <ProtectedRoute>
                <ActivityReports />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.CRUISE_REPORTS}
            element={
              <ProtectedRoute>
                <CruiseReports />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.TOTAL_REPORTS}
            element={
              <ProtectedRoute>
                <TotalReports />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.HOTEL_RADIUS}
            element={
              <ProtectedRoute>
                <HotelRadiusSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.HOTEL_VENDOR}
            element={
              <ProtectedRoute>
                <HotelVendorSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.HOTEL_AUTOSUGGEST}
            element={
              <ProtectedRoute>
                <HotelAutoSuggestSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.PAYMENT_GATEWAY}
            element={
              <ProtectedRoute>
                <PaymentGatewaySettings />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.SEARCHES}
            element={
              <ProtectedRoute>
                <Searches />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.MANAGE_AGENCIES}/:id`}
            element={
              <ProtectedRoute>
                <AgencyDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.MANAGE_AGENCIES}/:id/:agentId`}
            element={
              <ProtectedRoute>
                <AssociatedClientDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.MANAGE_AGENCIES}/settings/:id`}
            element={
              <ProtectedRoute>
                <AgencySettings />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.MANAGE_ADMINS}/add`}
            element={
              <ProtectedRoute>
                <AdminDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.MANAGE_ADMINS}/edit/:id`}
            element={
              <ProtectedRoute>
                <AdminDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.COUNTRY}
            element={
              <ProtectedRoute>
                <CountriesSetting />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.LOCATIONS}
            element={
              <ProtectedRoute>
                <LocationsSetting />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.CRUISE_LINE}
            element={
              <ProtectedRoute>
                <CruiseLineSetting />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.OTP}
            element={
              <ProtectedRoute>
                <OTPSetting />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.HIDE_HEADER}
            element={
              <ProtectedRoute>
                <HideHeaderSetting />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Layout>
    </>
  );
};

export default App;
