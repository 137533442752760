import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getHotelRadius = createAsyncThunk('GET HOTEL RADIUS', async (thunkAPI) => {
  const response = await axios.get(URL.HOTEL_RADIUS, thunkAPI);
  return response.data;
});

export const updateHotelRadius = createAsyncThunk('UPDATE HOTEL RADIUS', async (thunkAPI) => {
  const response = await axios.patch(URL.HOTEL_RADIUS, thunkAPI);
  return response.data;
});

export const getHotelVendors = createAsyncThunk('GET HOTEL VENDORS', async (thunkAPI) => {
  const response = await axios.get(URL.HOTEL_VENDORS, thunkAPI);
  return response.data;
});

export const updateHotelVendors = createAsyncThunk('UPDATE HOTEL VENDORS', async (thunkAPI) => {
  const response = await axios.patch(URL.HOTEL_VENDORS, thunkAPI);
  return response.data;
});
export const getCurrentAutoSuggestVendor = createAsyncThunk('GET HOTEL AUTOSUGGEST', async (thunkAPI) => {
  const response = await axios.get(URL.HOTEL_AUTOSUGGEST, thunkAPI);
  return response.data;
});

export const updateCurrentAutoSuggestVendor = createAsyncThunk('UPDATE HOTEL AUTOSUGGEST', async (thunkAPI) => {
  const response = await axios.patch(URL.HOTEL_AUTOSUGGEST, thunkAPI);
  return response.data;
});
export const getPaymentGateways = createAsyncThunk('GET PAYMENT GATEWAYS', async (thunkAPI) => {
  const response = await axios.get(URL.PAYMENT_GATEWAYS, thunkAPI);
  return response.data;
});

export const updatePaymentGateways = createAsyncThunk('UPDATE PAYMENT GATEWAYS', async (thunkAPI) => {
  const response = await axios.patch(URL.PAYMENT_GATEWAYS, thunkAPI);
  return response.data;
});

export const updateGlobalPaymentGatewayStatus = createAsyncThunk('UPDATE GLOBAL PYAMENT GATEWAY STATUS', async (thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_GLOBAL_PAYMENT_GATEWAY_STATUS, thunkAPI);
  return response.data;
});

export const updateGlobalHotelVendorStatus = createAsyncThunk('UPDATE GLOBAL HOTEL VENDOR STATUS', async (thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_GLOBAL_HOTEL_VENDOR_STATUS, thunkAPI);
  return response.data;
});

export const getOTPEnableStatus = createAsyncThunk('GET OTP ENABLE STATUS', async (thunkAPI) => {
  const response = await axios.get(URL.GLOBAL_OTP_SIGNUP_STATUS, thunkAPI);
  return response.data;
});

export const updateOTPEnableStatus = createAsyncThunk('UPDATE OTP ENABLE STATUS', async (thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_GLOBAL_OTP_SIGNUP_STATUS, thunkAPI);
  return response.data;
});

export const getGlobalHideHeaderforDealProposalStatus = createAsyncThunk('GET HIDE HEADER STATUS', async (thunkAPI) => {
  const response = await axios.get(URL.GLOBAL_HIDE_HEADER_STATUS, thunkAPI);
  return response.data;
});

export const updateGlobalHideHeaderforDealProposalStatus = createAsyncThunk('UPDATE HIDE HEADER STATUS', async (thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_GLOBAL_HIDE_HEADER_STATUS, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  settingsData: null,
  isSuccess: false,
  OTPSignupData: null,
  hideHeaderData: null
};

export const activitySlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: {
    // get hotel radius
    [getHotelRadius.pending]: (state) => {
      state.isLoading = true;
      state.settingsData = null;
    },
    [getHotelRadius.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.settingsData = action.payload.data;
    },
    [getHotelRadius.rejected]: (state, action) => {
      state.isLoading = false;
      state.settingsData = null;
      toast.error(action?.payload?.message);
    },

    // update hotel radius
    [updateHotelRadius.pending]: (state) => {
      state.isLoading = true;
      state.settingsData = null;
    },
    [updateHotelRadius.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.settingsData = action.payload.data;
    },
    [updateHotelRadius.rejected]: (state, action) => {
      state.isLoading = false;
      state.settingsData = null;
      toast.error(action?.payload?.message);
    },

    //get hotel vendors
    [getHotelVendors.pending]: (state) => {
      state.isLoading = true;
      state.settingsData = null;
    },
    [getHotelVendors.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.settingsData = action.payload.data;
    },
    [getHotelVendors.rejected]: (state, action) => {
      state.isLoading = false;
      state.settingsData = null;
      toast.error(action?.payload?.message);
    },

    //get hotel Autosuggest
    [getCurrentAutoSuggestVendor.pending]: (state) => {
      state.isLoading = true;
      state.settingsData = null;
    },
    [getCurrentAutoSuggestVendor.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.settingsData = action.payload.data;
    },
    [getCurrentAutoSuggestVendor.rejected]: (state, action) => {
      state.isLoading = false;
      state.settingsData = null;
      toast.error(action?.payload?.message);
    },
    //update hotel Autosuggest
    [updateCurrentAutoSuggestVendor.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.settingsData = null;
    },
    [updateCurrentAutoSuggestVendor.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [updateCurrentAutoSuggestVendor.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.settingsData = null;
      toast.error(action?.payload?.message);
    },

    //update hotel vendors
    [updateHotelVendors.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.settingsData = null;
    },
    [updateHotelVendors.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [updateHotelVendors.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.settingsData = null;
      toast.error(action?.payload?.message);
    },

    //get payment gateways
    [getPaymentGateways.pending]: (state) => {
      state.isLoading = true;
      state.settingsData = null;
    },
    [getPaymentGateways.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.settingsData = action.payload.data;
    },
    [getPaymentGateways.rejected]: (state, action) => {
      state.isLoading = false;
      state.settingsData = null;
      toast.error(action?.payload?.message);
    },

    //update payment gateways
    [updatePaymentGateways.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.settingsData = null;
    },
    [updatePaymentGateways.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [updatePaymentGateways.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.settingsData = null;
      toast.error(action?.payload?.message);
    },

    [updateGlobalPaymentGatewayStatus.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.settingsData = null;
    },
    [updateGlobalPaymentGatewayStatus.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [updateGlobalPaymentGatewayStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.settingsData = null;
      toast.error(action?.payload?.message);
    },

    [updateGlobalHotelVendorStatus.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.settingsData = null;
    },
    [updateGlobalHotelVendorStatus.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [updateGlobalHotelVendorStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.settingsData = null;
      toast.error(action?.payload?.message);
    },

    [getOTPEnableStatus.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.OTPSignupData = null;
    },
    [getOTPEnableStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.OTPSignupData = action.payload.data;
      state.isSuccess = true;
    },
    [getOTPEnableStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.OTPSignupData = null;
      toast.error(action?.payload?.message);
    },

    [updateOTPEnableStatus.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.OTPSignupData = null;
    },
    [updateOTPEnableStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.OTPSignupData = action.payload.data;
      state.isSuccess = true;
    },
    [updateOTPEnableStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.OTPSignupData = null;
      toast.error(action?.payload?.message);
    },

    [getGlobalHideHeaderforDealProposalStatus.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.hideHeaderData = null;
    },
    [getGlobalHideHeaderforDealProposalStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hideHeaderData = action.payload.data;
      state.isSuccess = true;
    },
    [getGlobalHideHeaderforDealProposalStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hideHeaderData = null;
      toast.error(action?.payload?.message);
    },

    [updateGlobalHideHeaderforDealProposalStatus.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.hideHeaderData = null;
    },
    [updateGlobalHideHeaderforDealProposalStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hideHeaderData = action.payload.data;
      state.isSuccess = true;
    },
    [updateGlobalHideHeaderforDealProposalStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hideHeaderData = null;
      toast.error(action?.payload?.message);
    },
  },
});

export default activitySlice.reducer;